import React, { useState } from 'react'
import Dropzone from 'react-dropzone'

import Label from '../../../../uiKit/texts/Label'
import { PhotoIcon } from '../../../../uiKit/icons/Icons'
import { handleWidgetLogoDrop } from '../../api/widget'
import { TWO_MB } from '../../../../constants/dataSize'

import classes from './styles.module.scss'
import {DropzoneType} from '../../../../models/DropzoneTypes'

interface Props {
  value: string
  onChange: (a: string) => void
  dropzoneType: DropzoneType
}

const LogoDropzone: React.FC<Props> = ({ value, onChange, dropzoneType }) => {
  const [errors, setErrors] = useState<string[]>([])

  const onDrop = (files: any[]) => {
    setErrors([])
    if (files[0] && files[0].size > TWO_MB) {
      const errors = []
      errors.push('Maximum file size must be less than or equal to 2 Mb.')
      setErrors(errors)
    } else {
      const file = new FormData()
      file.append('file', files[0])
      handleWidgetLogoDrop(file, onChange)
    }
  }

  return (
    <div className={classes.wrap}>
      <Label>Choose widget logo</Label>
      <Dropzone
        accept={'image/jpeg, image/png, image/jpg, image/gif'}
        className={classes.container}
        onDrop={onDrop}
        style={{ borderColor: !!errors.length && 'var(--color-text-error)' }}>
        {value ? (
          <img src={value} alt="file" className={classes.image} />
        ) : (
          <div className={classes.uploadContainer}>
            <div className={classes.iconWrap}>
              <PhotoIcon height="15px" width="19px" />
            </div>
            <p className={classes.label}>
              Drag and drop or <span>Upload</span>
            </p>
            <p className={classes.description}>2 MB max .jpeg or .png</p>
          </div>
        )}
      </Dropzone>
      {!!errors.length && <p className={classes.errorMessage}>{errors[0]}</p>}
    </div>
  )
}

export default LogoDropzone
